<template>
  <Stack
    tag="div"
    justify="between"
    align="start"
    class="ui-px-[8px] ui-py-[10px] ui-rounded-sm"
    :class="variantClasses[variant]"
  >
    <Stack tag="div" gap="2xs">
      <Icon name="warning-solid" :size="16" class-name="mt-[9px]" />
      <Text size="sm" :data-test-id="bannerTestId" :data-fs="bannerTestId">
        <slot />
      </Text>
    </Stack>
    <Stack
      tag="div"
      gap="xs"
      align="center"
      @click="emit('dismiss')"
      class="ui-cursor-pointer"
    >
      <Text tag="span" size="sm" weight="bold" class="ui-text-blue-light">
        {{ action }}
      </Text>
      <Icon
        v-if="dismissable"
        name="times-thin"
        :size="16"
        class-name="mt-[9px]"
      />
    </Stack>
  </Stack>
</template>

<script setup lang="ts">
import type { BannerProps } from "./types";
import Text from "../../Typography/Text/Text.vue";
import Icon from "../../Icon/Icon.vue";
import Stack from "../../Layout/Stack/Stack.vue";

const { variant, action, dismissable } = defineProps<BannerProps>();

const emit = defineEmits(["dismiss"]);

const variantClasses = {
  ["error"]: "ui-bg-red-lighter ui-text-red-dark",
  ["warning"]: "ui-bg-orange-lighter ui-text-orange-dark",
  ["neutral"]: "ui-bg-grey-lighter ui-text-charcoal-default",
  ["information"]: "ui-bg-blue-lighter ui-text-blue-dark",
  ["success"]: "ui-bg-green-lighter ui-text-green-dark",
};
</script>
